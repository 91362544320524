import React, { createContext, useCallback, useContext, useMemo } from "react";

const PageContext = createContext();

export default PageContext;

export const Page = ({ children, pageContext }) => {
  const context = useMemo(() => ({ ...pageContext }), [pageContext]);

  return (
    <PageContext.Provider value={context}>{children}</PageContext.Provider>
  );
};

export const usePageContext = () => {
  return useContext(PageContext);
};

export const useLocalize = () => {
  const { lang, l } = useContext(PageContext);

  const slug = useCallback(
    (slug) => {
      const { slugs } = l;
      if (slugs && slugs[slug]) {
        return slugs[slug].replace(/\/\//gi, "/");
      }

      if (slugs && slugs[slug.substr(0, slug.length - 1)]) {
        return (
          slugs[slug.substr(0, slug.length - 1)].replace(/\/\//gi, "/") + "/"
        );
      }

      return slug;
    },
    [l]
  );

  return { lang, localize: l, slug };
};
